<template>
  <v-container class="news-detail wp-page" @click="clicked" v-if="page">
    <breadcrumb :items="bredcrumb" />
    <router-link
      class="d-flex align-center news-detail-go-back black--text"
      :to="{
        name: 'NewsList'
      }"
    >
      <v-icon color="primary" class="pr-2">$chevronLeft</v-icon>
      <span class="font-weight-bold primary--text">{{
        $t("news.goBack")
      }}</span></router-link
    >
    <h1 class="mt-3 mb-6" v-html="title"></h1>
    <v-img
      v-if="page && page.featured_image_url"
      class="mb-3"
      :src="page.featured_image_url"
    />
    <time>{{ $dayjs(page.date).format("LL") }} </time>
    <div class="mt-6" v-if="content">
      <v-runtime-template :template="template" />
    </div>

    <div v-if="page.box_2" v-html="page.box_2"></div>
    <div v-if="page.box_3" v-html="page.box_3"></div>
    <div v-if="page.box_4" v-html="page.box_4"></div>
  </v-container>
</template>
<style lang="scss">
.news-detail {
  &-go-back {
    text-decoration: none;
  }
  img {
    width: 100%;
    height: auto;
  }
}
</style>
<script>
import clickHandler from "~/mixins/clickHandler";
import VRuntimeTemplate from "v-runtime-template";
import get from "lodash/get";

import CMService from "~/service/cmService";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import page from "~/mixins/page";
import {
  VImg,
  VBtn,
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelContent,
  VExpansionPanelHeader,
  VAlert,
  VTooltip,
  VCard,
  VCardActions,
  VCardSubtitle,
  VCardText,
  VCardTitle,
  VDivider,
  VList,
  VListItem,
  VListItemContent,
  VListItemSubtitle,
  VListItemTitle,
  VDialog
} from "vuetify/lib";
/* eslint-disable vue/no-unused-components */

export default {
  components: {
    VRuntimeTemplate,
    VImg,
    VBtn,
    VExpansionPanel,
    VExpansionPanels,
    VExpansionPanelContent,
    VExpansionPanelHeader,
    VAlert,
    VTooltip,
    VCard,
    VCardActions,
    VCardSubtitle,
    VCardText,
    VCardTitle,
    VDivider,
    VList,
    VListItem,
    VListItemContent,
    VListItemSubtitle,
    VListItemTitle,
    VDialog,
    Breadcrumb
  },
  name: "NewsDetail",
  mixins: [clickHandler, page],
  data() {
    return {
      template: null,
      currentDate: null,
      cssUrl: ""
    };
  },
  methods: {
    async fetchNews(slug) {
      const res = await CMService.getCustomPostBySlug("posts", slug);
      if (res && res.length) {
        this.page = res[0];
      }
    }
  },
  async mounted() {
    const timestamp = new Date().getTime();
    this.currentDate = timestamp;
    const cssUrl = `/p/wp-content/themes/ali/styles-common.css?cache_buster=${this.currentDate}`;

    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.type = "text/css";
    link.href = cssUrl;

    document.head.appendChild(link);

    await this.fetchNews(this.$route.params.postName);
    if (this.content) {
      this.template = `<div>${this.content}</div>`;
    }
  },
  watch: {
    "$route.params.postName"() {
      this.fetchNews(this.$route.params.postName);
    }
  },
  metaInfo() {
    if (this.page) {
      let title =
        get(this.page, "meta.seo_title") || get(this.page, "title.rendered");
      let description = "Leggi online le news della Alí Magazine";
      if (get(this.page, "fixed_excerpt")) {
        description = "Alí news: " + get(this.page, "fixed_excerpt");
      }
      return {
        title: title,
        link: [
          {
            rel: "canonical",
            href: `https://${window.location.host}${this.$route.path}`
          }
        ],
        meta: [
          {
            vmid: "description",
            name: "description",
            content: description
          },
          // Open Graph Metas
          {
            vmid: "og:locale",
            property: "og:locale",
            content: "it_IT"
          },
          {
            vmid: "og:title",
            property: "og:title",
            content: title
          },
          {
            vmid: "og:type",
            property: "og:type",
            content: "website"
          },
          {
            vmid: "og:url",
            property: "og:url",
            content: location.href
          },
          {
            vmid: "og:image",
            property: "og:image",
            content: this.page.featured_image_url
          },
          {
            vmid: "og:image:alt",
            property: "og:image:alt",
            content: description
          },
          {
            vmid: "og:site_name",
            property: "og:site_name",
            content: global.config?.defaultPageName
          },
          // Twitter Metas
          {
            vmid: "twitter:card",
            name: "twitter:card",
            content: "summary_large_image"
          },
          {
            vmid: "twitter:title",
            name: "twitter:title",
            content: title
          },
          {
            vmid: "twitter:image",
            name: "twitter:image",
            content: this.page.featured_image_url
          },
          {
            vmid: "twitter:url",
            name: "twitter:url",
            content: location.href
          }
        ]
      };
    }
  }
};
</script>
